.history {
    background-color: #1B1E70;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
}

.history .history-img {
    width: 350px;
    margin-right: 80px;
}

.history .history-img img {
    width: 100%;
    display: block;
    border-radius: 6px;
}

.history .history-text {
    width: 46%;
    text-align: left;
}

.history .history-text h6 {
    /* border: 1px solid #5925DC;
    background-color: #F4F3FF;
    padding: 6px 16px; */
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 15px;
    color: #17B9DC;
    font-weight: 500;
}

.history .history-text p {
    font-size: 16px;
    color: #fff;
}

@media (max-width: 1000px) {
    .history {
        padding: 50px;
        align-items: center;
    }

    .history .history-img {
        margin-right: 40px;
    }
}

@media (max-width: 800px) {
    .history {
        flex-direction: column;
    }

    .history .history-img {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .history .history-text {
        width: 80%;
        text-align: center;
    }
}

@media (max-width: 640px) {
    .history .history-text {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .history .history-img {
        width: 300px;
    }

    .history .history-text h6 {
        font-size: 12px;
    }

    .history .history-text p {
        font-size: 16px;
        text-align: justify;
    }
}