.more_read {
    width: 90%;
    margin: 0 auto;
    padding: 0 0 60px 0;
}

.more_read h4 {
    font-size: 30px;
    text-align: left;
    margin-bottom: 20px;
}

.more_list {
    display: flex;
    gap: 40px;
}

.more_item {
    width: 30%;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.more_item img {
    display: block;
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
}

.more_subcontent {
    padding: 20px;
    text-align: left;
}

.more_subcontent h6 {
    font-size: 16px;
    margin-bottom: 10px;
}

.more_subcontent p {
    font-size: 14px;
}

@media (max-width:1100px) {

    .more_list {
        flex-wrap: wrap;
    }

    .more_item {
        width: 45%;
    }
}

@media (max-width:650px) {
    .more_read h4 {
        text-align: center;
    }

    .more_list {
        justify-content: center;
    }

    .more_item {
        width: 75%;
    }
}

@media (max-width:480px) {
    .more_item {
        width: 100%;
    }
}