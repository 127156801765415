.recentblog {
    padding: 70px 100px;
    text-align: left;
}

.recentblog h6 {
        /* border: 1px solid #5925DC;
        background-color: #F4F3FF; */
    padding: 6px 16px;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 56px;
    font-size: 15px;
    color: #5925DC;
    margin-left: 50px;
    font-weight: 500;
}

.recentblog .blog-list {
    display: flex;
    gap: 40px;
    justify-content: center;
}

.recentblog .blog-list .blog-item {
    width: 28%;
}

.recentblog .blog-list .blog-item img {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}

.recentblog .blog-list .blog-item h5 {
    font-size: 18px;
    margin-bottom: 10px;
}

.recentblog .blog-list .blog-item p {
    font-size: 16px;
}

@media (max-width: 1000px) {
    .recentblog {
        padding: 100px 40px;
    }

    .recentblog h6 {
        margin-left: 0;

    }
}

@media (max-width: 800px) {
    .recentblog .blog-list {
        flex-wrap: wrap;
    }

    .recentblog .blog-list .blog-item {
        width: 45%;
    }
}

@media (max-width: 640px) {
    .recentblog .blog-list .blog-item {
        width: 90%;
    }
}