.service-spotlight {
    padding: 100px;
    display: flex;
    justify-content: start;
    align-items: end;
    background: url(../../Assets/Images/Home.png);
    height: 230px;
    background-attachment: fixed;
}

.service-spotlight .content {
    width: 60%;
    text-align: left;
}

.service-spotlight .content h3 {
    color: #fff;
    font-size: 34px;
    font-family: 'Cocogoose';
    font-weight: 300;
}

.service-spotlight .content p {
    font-size: 18px;
    color: #fff;
}


@media (max-width: 1000px) {
    .service-spotlight .content {
        width: 80%;
    }
}

@media (max-width: 800px) {
    .service-spotlight .content {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .service-spotlight {
        padding: 100px 50px;
    }
}

@media (max-width: 480px) {
    .service-spotlight .content h3 {
        font-size: 24px;
    }

    .service-spotlight .content p {
        font-size: 16px;
    }
}