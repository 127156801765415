body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cocogoose';
    src: url('./Assets/fonts/cocogoose_trial.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}