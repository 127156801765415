* {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.home-canvas {
    position: relative;
    z-index: 99;
    /* position: absolute; */
    /* width: 100%; */
}