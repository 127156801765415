header {
    display: flex;
    background-color: #1E164E;
    height: 85px;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    box-sizing: border-box;
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
}

.overflow-hidden {
    overflow: hidden;
}
header .logo {
    width: 120px;
}

header .logo img {
    display: block;
    width: 100%;
}

header .nav {
    display: flex;
    gap: 30px;
}

header .nav li .link {
    font-size: 16px;
    color: #fff;
    position: relative;
}

header .nav .link:after {
    content: "";
    width: 100%;
    display: block;
    height: 2px;
    background-color: #F05A28;
    transform: translateY(10px);
    opacity: 0;
    border-radius: 20px;
    transition: .3s ease-in-out;
}

header .nav .link.active:after {
    opacity: 1;
    transform: translateY(6px);
}

/* header .nav li:hover::after {
    opacity: 1;
} */

header button {
    color: #fff;
    border-radius: 57px;
    border: 1px solid #F05A28;
    padding: 12px 16px;
    background: transparent;
    cursor: pointer;
    transition: .4s ease-in;
}

header button:hover {
    background-color: #F05A28;
    color: #fff;
}

.menu {
    display: none;
    width: 30px;
}

.open .menu span:nth-child(1) {
    transform: rotate(45deg) translateX(14px);
}

.open .menu span:nth-child(3) {
    transform: rotate(-45deg) translateX(14px);
}

.open .menu span:nth-child(2) {
    transform: scaleX(0);
}

.menu span {
    width: 100%;
    display: block;
    background-color: #fff;
    height: 3px;
    transition: .3s ease-in-out;
    border-radius: 35px;
}

.menu span:nth-child(2) {
    margin: 6px 0;
}


@media (max-width: 860px) {

    header .nav,
    header button {
        display: none;
    }

    .menu {
        display: block;
    }
}

@media (max-width: 500px) {
    header {
        padding: 0 20px;
    }

    header .logo {
        width: 100px;
    }
}