.service-regular {
    background-color: #F4F3FF;
    padding: 30px 100px;
}


.service-regular h3 {
    font-size: 36px;
    font-weight: 600;
}

.service-regular .service-list {
    display: flex;
    padding: 50px;
    background-color: #fff;
    border-radius: 32px 32px 0 0;
    flex-wrap: wrap;
    margin-top: 55px;
    gap: 50px;
}

.service-regular .service-list .item {
    width: 28%;
    text-align: left;
}

.service-regular .service-list .item .img-cont {
    display: flex;
    /* align-items: center; */
    margin-bottom: 16px;
    cursor: pointer;

}

.service-regular .service-list .item .img-cont img {
    width: 40px;
    margin-left: -14px;
    transition: transform 0.4s ease-in, margin-left 0.4s ease-in;
}

.service-regular .service-list .item .img-cont:hover img {
    transform: scale(1.01);
    margin-left: 0;
}

.service-regular .service-list .item .img-cont h5 {
    font-size: 20px;
    margin-left: 18px;
    font-weight: 400;
}

@media (max-width: 1000px) {
    .service-regular {
        padding: 100px 50px;
    }

    .service-regular .service-list .item {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .service-regular .service-list .item {
        width: 44%;
    }
}

@media (max-width: 640px) {
    .service-regular .service-list .item {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .service-regular .service-list .item .img-cont h5 {
        font-size: 14px;
    }

    .service-regular .service-list .item {
        width: 100%;
    }

    .service-regular h3 {
        font-size: 24px;
    }

    .service-regular h6 {
        font-size: 12px;
    }

    .service-regular {
        padding: 30px;
        padding-top: 0;
    }

    .service-regular .service-list {
        padding: 30px;
        gap: 18px;
        margin-top: 30px;
    }

    .service-regular .service-list .item .img-cont img {
        width: 30px;
    }
}