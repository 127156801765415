.contactdetails {
    padding: 70px 100px;
}

.contactdetails h6 {
    /* border: 1px solid #5925DC;
    background-color: #F4F3FF; */
    padding: 6px 16px;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 56px;
    font-size: 15px;
    color: #5925DC;
    font-weight: 500;
}

.contactdetails .main-bx {
    display: flex;
    justify-content: space-between;
}

.contactdetails .main-bx .details-left {
    width: 50%;
    padding: 10px;
    border-right: 1px solid #D0D5DD;
}

.contactdetails .main-bx .details-left .item .top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contactdetails .main-bx .details-left .item .top span {
    font-size: 18px;
    font-weight: 700;
}

.contactdetails .main-bx .details-left .item .top svg {
    color: #2d85f0;
    margin-right: 8px;
    width: 20px;
}

.contactdetails .main-bx .details-left .item {
    margin-bottom: 30px;
}

.contactdetails .main-bx .details-left .item p {
    text-align: left;
    margin-top: 20px;
    padding-left: 30px;
    cursor: pointer;
}

.contactdetails .main-bx form .touch {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
}

.contactdetails .main-bx form .touch h3 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 700;
}

.contactdetails .main-bx form .touch p {
    font-size: 18px;
    font-weight: 400;
}

.contactdetails .main-bx form {
    width: 50%;
    padding-left: 50px;
    text-align: left;
}

.contactdetails .main-bx form .top {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.contactdetails .main-bx form label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
    font-weight: 500;
}

.contactdetails .main-bx form .box {
    width: 100%;
}

.contactdetails .main-bx form .box input {
    width: 100%;
}


.contactdetails .main-bx form input {
    background-color: #F6F6F6;
    border-radius: 4px;
    padding: 18px 16px;
    border: none;
    outline: none;
    margin-bottom: 10px;
    box-sizing: border-box;

}

.contactdetails .main-bx form textarea {
    background-color: #F6F6F6;
    border-radius: 4px;
    padding: 18px 16px;
    border: none;
    outline: none;
    margin-bottom: 10px;
    box-sizing: border-box;

    width: 100%;
    height: 190px;
}

.contactdetails .main-bx form input.namee {
    width: 100%;
}

.contactdetails .main-bx form input.msg {
    display: block;
    width: 100%;
    margin-bottom: 28px;
}

.contactdetails .main-bx form button {
    background-color: #000;
    border-radius: 4px;
    padding: 18px 28px;
    color: #fff;
    display: inline-block;
    width: 50%;
    margin: 20px auto 0;
    box-sizing: border-box;
    cursor: pointer;
    transition: .4s ease-in;
}

.contactdetails .main-bx form button:hover {
    background-color: #fff;
    color: #000;
}


.map {
    border-radius: 12px;
    margin-bottom: 16px;
    width: 380px;
    height: 200px;
    margin-left: -160px;
}

@media (max-width: 1280px) {
    .map {
        width: 350px;
        margin-left: -100px;
    }
}

@media (max-width: 1100px) {
    .map {
        width: 320px;
        margin-left: -50px;
    }
}

@media (max-width: 1000px) {
    .contactdetails .main-bx {
        flex-direction: column;
    }

    .contactdetails .main-bx .details-left {
        width: 80%;
        border: 0;
        margin: 0 auto 40px;
    }

    .contactdetails .main-bx .details-left .item .top {
        justify-content: center;
    }

    .contactdetails .main-bx .details-left .item p {
        text-align: center;
    }

    .contactdetails .main-bx form {
        width: 80%;
        text-align: center;
    }
}

@media (max-width: 800px) {
    .contactdetails {
        padding: 100px 50px;
    }
}

@media (max-width: 640px) {
    .contactdetails .main-bx form .top {
        flex-wrap: wrap;
    }

}

@media (max-width: 480px) {
    .contactdetails {
        padding: 100px 34px;
    }

    .contactdetails .main-bx form {
        padding-left: 0;
    }
    .contactdetails .main-bx .details-left .item {
        margin-bottom: 5px;
    }

    .contactdetails .main-bx form input,
    .contactdetails .main-bx form input.msg {
        width: 100%;
        text-align: center;

    }

    .contactdetails .main-bx .details-left .item .top svg {

        margin-right: 0px;
        display: none;
       
    }

    .map {
        width: 270px;
        margin: 0 auto;
    }

    .contactdetails .main-bx form {
        width: 100%;
    }

    .contactdetails .main-bx form label,
    .contactdetails .main-bx form .box input {
        text-align: center;
    }

    .contactdetails .main-bx .details-left .item p {
        padding: 0;
    }

    .contactdetails .main-bx .details-left {
        width: 100%;
        padding: 0;
    }

}