.service-business {
    background-color: #F4F3FF;
    padding: 30px 100px;
}

.service-business h6 {
    /* border: 1px solid #5925DC;
    background-color: #F4F3FF; */
    padding: 6px 16px;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 15px;
    color: #5925DC;
    font-weight: 500;
}

.service-business h3 {
    font-size: 36px;
    font-weight: 600;
}

.service-business .service-list {
    display: flex;
    padding: 50px;
    background-color: #fff;
    border-radius: 32px 32px 0 0;
    flex-wrap: wrap;
    margin-top: 55px;
    gap: 50px;
}

.service-business .service-list .item {
    width: 29%;
    text-align: left;
}

.service-business .service-list .item .img-cont {
    display: flex;
    /* align-items: center; */
    margin-bottom: 16px;
    cursor: pointer;
    transition: .4s ease-in;
}

.service-business .service-list .item .img-cont:hover img {
    transform: scale(1.01);
    margin-left: 0;
    /* Adjust the margin as needed */
}

.service-business .service-list .item .img-cont img {
    width: 40px;
    margin-left: -14px;
    transition: transform 0.4s ease-in, margin-left 0.4s ease-in;
}

.service-business .service-list .item .img-cont h5 {
    font-size: 20px;
    margin-left: 18px;
    font-weight: 400;
}

.service-business .service-list .item P {
    color: #667085;
    font-size: 16px;
    width: 74%;
    margin-left: 50px;
    text-align: left;
}

@media (max-width: 1100px) {
    .service-business .service-list .item {
        width: 40%;
    }
}

@media (max-width: 1000px) {
    .service-business {
        padding: 100px 50px;
    }

}

@media (max-width: 800px) {
    .service-business .service-list .item {
        width: 44%;
    }
}

@media (max-width: 640px) {
    .service-business .service-list .item {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .service-business .service-list .item .img-cont h5 {
        font-size: 14px;
    }

    .service-business .service-list .item {
        width: 100%;
    }

    .service-business h3 {
        font-size: 24px;
    }

    .service-business h6 {
        font-size: 12px;
        margin-bottom: 0;
    }

    .service-business {
        padding: 30px;
    }

    .service-business .service-list {
        margin-top: 20px;
        padding: 30px 10px 30px 30px;
        gap: 18px;
    }

    .service-business .service-list .item .img-cont img {
        width: 30px;
    }
}