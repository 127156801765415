.review {
    padding: 80px 100px;
    background-color: #1b1e70;
    display: flex;
    justify-content: space-between;
}

.review .top-review {
    display: flex;
    /* justify-content: center; */
    /* margin-bottom: 40px; */
    align-items: center;
    width: 38%;
}

.review .top-review h4 {
    /* border: 1px solid #5925DC;
    background-color: #F4F3FF;
    padding: 6px 16px; */
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 12px;
    color: #17B9DC;
    text-transform: uppercase;
    font-weight: 500;
}

.review .top-review .left-review {
    width: 100%;
    /* text-align: left; */
}

.review .top-review h5 {
    color: #fff;
    font-size: 38px;
    /* margin-bottom: 20px; */
    font-weight: 600;
}

.review .top-review .valuation {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
}

.review .top-review .valuation .total h6,
.review .top-review .valuation .rating h6 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
}

.review .top-review .valuation .amout,
.review .top-review .amout-rate {
    display: flex;
    gap: 40px;
}

.review .top-review .valuation .amout p,
.review .top-review .amout-rate p {
    margin-top: 28px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
}

.review .top-review .valuation .amout span {
    font-size: 20px;
    color: #fff;
    background-color: #2e90fa;
    padding: 2px 24px;
    border-radius: 30px;
    display: inline-block;
    height: 30px;
}

.review .top-review .amout-rate img {
    width: 100px;
    display: block;
}

.review .top-review .right-review {
    width: 400px;
}

.review .top-review .right-review img {
    display: block;
    width: 100%;
}

.bottom-review {
    /* padding: 0px 50px 50px 50px; */
    width: 56%;
}

.navigation-buttons {
    position: absolute;
    top: 82%;
    right: 0;
    width: 22%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.prev-button,
.next-button {
    background-color: #5925DC;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.prev-button:hover,
.next-button:hover {
    background-color: #4A1FB8;
}

.review .bottom-review .item {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    /* border-top: 1px solid #4A1FB8; */
    /* padding-top: 30px; */
    margin-bottom: 30px;
}


.bottom-review .item .info {
    width: 80%;
    margin: 0 auto;
}

.bottom-review .item .info .info-details {
    display: flex;
    /* justify-content: center; */
    padding-top: 20px;
}

.bottom-review .item .info .info-details .in-img {
    width: 100px;
    margin-right: 18px;
}

.bottom-review .item .info .info-details img {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.bottom-review .item .info .info-details h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
}

.bottom-review .item .info .info-details h5 {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.bottom-review .item .info .info-details span {
    font-size: 14px;
    color: #fff;
    display: block;
    margin-bottom: 10px;
}

.bottom-review .item .info .info-details span,
.bottom-review .item .info .info-details small {
    font-size: 12px;
    color: #fff;
    display: block;
}

.bottom-review .item .details {
    width: 80%;
    text-align: center;
    margin: 0 auto 30px;
}

.bottom-review .item .details h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
}

.bottom-review .item .details p {
    font-size: 16px;
    color: #fff;
    text-align: left;
    /* font-family: 'Arial', sans-serif;  */

}

.bottom-review .item .details p::before,
.bottom-review .item .details p::after {
    content: '"';
    color: #e7c096;
    /* Adjust quote color */
    font-size: 30px;
    /* Adjust quote size */
    /* vertical-align: middle; Adjust vertical alignment */

}

/* .bottom-review .item .like {
    width: 80px;
} */

.bottom-review .item .like img {
    display: block;
    width: 100%;
}


@media (max-width: 1100px) {
    .review {
        padding: 80px 40px;
    }

    .review .top-review h5 {
        font-size: 36px;
    }
}

/* @media (max-width: 800px) {

    .review .top-review h5 {
        font-size: 36px;
    }

    .review .top-review .valuation .total h6,
    .review .top-review .valuation .rating h6 {
        font-size: 18px;
    }

    .review .top-review .right-review {
        width: 350px;
    }

    .bottom-review {
        padding-left: 0;
        padding-right: 0;
    }
} */

@media (max-width: 900px) {
    .review {
        flex-direction: column;
    }

    .review .top-review {
        width: 80%;
        margin: 0 auto 40px;
    }

    .bottom-review {
        padding-left: 0;
        padding-right: 0;
    }

    .bottom-review .item .details {
        width: 100%;
    }

    .bottom-review .item .info {
        width: 100%;
    }

    .bottom-review {
        width: 80%;
        margin: 0 auto;
    }

    .bottom-review .item .details p {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .bottom-review {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .review .top-review .right-review {
        width: 270px;
    }

    .review .top-review h5 {
        font-size: 30px;
    }

    .review .top-review .valuation .total h6,
    .review .top-review .valuation .rating h6 {
        font-size: 16px;
    }

    .review .top-review .valuation .amout p,
    .review .top-review .amout-rate p {
        font-size: 26px;
    }

    .review .top-review .valuation {
        flex-direction: column;
        justify-content: center;
    }

    .review .top-review .valuation .amout,
    .review .top-review .amout-rate {
        justify-content: center;
    }

    .bottom-review .item .details p {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .review {
        padding: 80px 24px;
    }

    .review .top-review .right-review {
        width: 270px;
    }

    .review .top-review h5 {
        font-size: 26px;
    }

    .bottom-review {
        width: 100%;
    }

    .review .top-review .valuation .amout,
    .review .top-review .amout-rate {
        justify-content: center;
    }
}

@media (max-width: 410px) {

    .bottom-review .item .details p {
        font-size: 14px;
    }

    .bottom-review .item .info .info-details .in-img {
        width: 80px;
    }

    .bottom-review .item .info .info-details {
        align-items: center;
    }

    .bottom-review .item .info .info-details h4 {
        font-size: 16px;
    }

    .bottom-review .item .info .info-details h5 {
        font-size: 12px;
    }

    .bottom-review {
        padding-bottom: 0;
    }
}