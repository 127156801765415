.about-choose {
    padding: 100px;
}

.about-choose h6 {
    /* border: 1px solid #5925DC;
        background-color: #F4F3FF; */
    padding: 6px 16px;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 15px;
    color: #5925DC;
    font-weight: 500;
}

.about-choose h3 {
    font-size: 36px;
    margin-bottom: 50px;
}

.container-choose {
    display: flex;
    justify-content: space-evenly;
    position: relative;
}

.about-choose .choose-text {
    width: 40%;
}

.about-choose .choose-text p {
    width: 80%;
    margin-bottom: 50px;
    text-align: left;
}

.about-choose .advantege {
    text-align: left;
}

.about-choose .advantege li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.about-choose .advantege li img {
    margin-right: 8px;
}

.about-choose .choose-imgg {
    width: 350px;
}

.about-choose .choose-imgg img {
    width: 100%;
    display: block;
    border-radius: 12px;
}

.about-ch-img {
    position: absolute;
    right: 350px;
    top: 120px;
    width: 180px;
}

@media (max-width: 1280px) {
    .about-ch-img {
        right: 300px;
    }
}

@media (max-width: 1000px) {
    .about-choose {
        padding: 100px 30px;
    }

    .about-ch-img {
        right: 260px;
    }
}

@media (max-width: 800px) {
    .container-choose {
        flex-direction: column-reverse;
    }

    .about-choose .choose-text {
        width: 100%;
    }

    .about-choose .choose-text p {
        margin: 40px auto;
        text-align: center;
    }

    .about-choose .choose-imgg {
        margin: 0 auto;
        width: 300px;
    }

    .about-choose .advantege li {
        justify-content: center;
    }

    .about-ch-img {
        right: 250px;
        top: 90px;
    }
}

@media (max-width: 640px) {
    .about-choose {
        padding-bottom: 20px;
    }

    .about-ch-img {
        display: none;
    }
}

@media (max-width: 480px) {
    .about-choose h3 {
        font-size: 30px;
    }

    .about-choose .choose-imgg {
        width: 280px;
    }

    .about-choose .advantege {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .about-choose .choose-text p {
        text-align: justify;
    }

    .about-choose .advantege li {
        padding-left: 30px;
    }
}