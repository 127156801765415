.comingSoon {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comingSoon h2 {
    font-size: 30px;

}