.help {
    background-color: #1B1E70;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.help h3 {
    font-size: 32px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 600;

}

.help p {
    font-size: 18px;
    color: #fff;
}

@media (max-width: 640px) {
    .help h3 {
        font-size: 30px;
    }
}

@media (max-width: 480px) {
    .help h3 {
        font-size: 26px;
    }

    .help p {
        font-size: 16px;
    }
}